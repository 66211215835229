.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  font-family: monospace;
}

.text-container {
  position: absolute;
  top: 15%;
  left: 20px;
  width: 100%;
  max-width: 400px;
}

.date-time {
  margin-bottom: 8px;
}

.command-line {
  display: flex;
}

.cursor {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0.6em;
  background-color: white;
  animation: blink 0.7s infinite;
}

.dots-container {
  position: relative;
  width: 48px;
  height: 48px;
}

.dot {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
}

.dot-1 {
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  animation: sequentialFade 3s infinite;
}

.dot-2 {
  bottom: 0;
  left: 0;
  animation: sequentialFade 3s infinite -1s;
}

.dot-3 {
  bottom: 0;
  right: 0;
  animation: sequentialFade 3s infinite -2s;
}

@keyframes sequentialFade {
  0%,
  83.33%,
  100% {
    opacity: 1;
  }
  33.33%,
  38.33% {
    opacity: 0;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
