@import url("https://fonts.googleapis.com/css2?family=VT323&family=Space+Grotesk&display=swap");

body {
  font-family: "Space Grotesk", sans-serif;
}

.syn-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2vh 5vw;
  position: relative;
}

.syn-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3px;
  z-index: 2;
  position: absolute;
  top: 12%;
  left: 2vw;
}

.syn-title-container {
  display: flex;
  align-items: baseline;
}

.syn-title {
  font-family: "VT323", monospace;
  font-size: 4rem;
  margin: 0;
  color: #333;
}

.syn-version {
  font-size: 1rem;
  margin-left: 10px;
  color: #666;
}

.syn-description {
  font-family: "Space Grotesk", sans-serif;
  font-size: 1rem;
  color: #555;
  max-width: 500px;
  margin-top: 5px;
}

.chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 1em;
  max-width: 800px; /* Add this line */
  max-height: 1600px; /* Add this line */
  margin-left: auto; /* Add this line */
  margin-right: auto; /* Add this line */
}

.neon-dot-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  z-index: 0;
}

.neon-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ff0000;
  box-shadow: 0 0 20px #ff0000, 0 0 40px #ff0000, 0 0 60px #ff0000;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0 0 20px #ff0000, 0 0 40px #ff0000, 0 0 60px #ff0000;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    box-shadow: 0 0 30px #ff0000, 0 0 60px #ff0000, 0 0 90px #ff0000;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0 0 20px #ff0000, 0 0 40px #ff0000, 0 0 60px #ff0000;
  }
}

.chat-window {
  width: 70%;
  max-width: 500px;
  height: 60%;
  background-color: rgba(222, 222, 222, 0.4);
  backdrop-filter: blur(9px);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  z-index: 1;
  justify-content: space-between;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message {
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 70%;
  font-family: Helvetica, Arial, sans-serif;
}

.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.ai {
  background-color: #f1f1f1;
  color: black;
  align-self: flex-start;
}

.chat-input {
  display: flex;
  padding: 5px;
  background: rgba(222, 222, 222, 0.4);
  height: 40px; /* Ensure the height is fixed for consistency */
}

.chat-input input {
  flex-grow: 1;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
}

.chat-input button {
  padding: 10px 20px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  color: #007bff;
  border: 1px solid #d1d1d1;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: -2px -2px 5px rgba(255, 255, 255, 1),
    2px 2px 5px rgba(0, 0, 0, 0.1), inset 1px 1px 2px rgba(255, 255, 255, 0.1),
    inset -1px -1px 2px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.chat-input button::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(45deg);
  pointer-events: none;
}

.chat-input button:hover {
  background: linear-gradient(145deg, #f0f0f0, #e6e6e6);
  color: #0056b3;
  box-shadow: -1px -1px 3px rgba(255, 255, 255, 0.6),
    1px 1px 3px rgba(0, 0, 0, 0.1), inset -1px -1px 2px rgba(255, 255, 255, 0.7),
    inset 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.chat-input button:active {
  background: linear-gradient(145deg, #d9d9d9, #f0f0f0);
  box-shadow: inset -1px -1px 3px rgba(255, 255, 255, 0.7),
    inset 1px 1px 3px rgba(0, 0, 0, 0.2);
  transform: translateY(1px);
}

.error-message {
  color: black;
  text-align: center;
  padding: 10px;
}

.typing-indicator {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  background-color: #666;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  animation: typing 1s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
  animation-delay: 0s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
  margin-right: 0;
}

@keyframes typing {
  0% {
    transform: translateY(0px);
    background-color: #666;
  }
  28% {
    transform: translateY(-7px);
    background-color: #999;
  }
  44% {
    transform: translateY(0px);
    background-color: #666;
  }
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2;
}

.control {
  margin-bottom: 10px;
}

.control label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.control input {
  width: 200px;
}

@media (max-width: 768px) {
  .syn-container {
    padding: 2vh 2vw;
  }

  .syn-header {
    width: 100%;
    align-items: center;
    text-align: center;
    top: 9%;
    left: 0;
    right: 0;
  }

  .syn-title {
    font-size: 2.5rem;
  }

  .syn-version {
    font-size: 0.8rem;
  }

  .syn-description {
    font-size: 0.9rem;
    text-align: center;
  }

  .chat-window {
    width: 90%;
    min-height: 550px;
    margin-bottom: 0.9em;
    padding-bottom: 10px;
  }

  .chat-input {
    padding-bottom: env(
      safe-area-inset-bottom
    ); /* For mobile bottom safe area */
    height: 40px; /* Keep height fixed */
  }
}
