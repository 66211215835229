@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700&display=swap");

html,
body,
#root {
  height: 100%;
  margin: 0;
  font-family: "EB Garamond";
  font-weight: 500;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #f0eeee;
  background-image: radial-gradient(circle at 1px 1px, #00000018 1px, transparent 0);
  background-size: 20px 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
div {
  font-weight: inherit;
}
