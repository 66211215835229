.interface-container {
  height: calc(100vh - 60px);
  position: relative;
  padding-top: 60px;
}

.folder-icon {
  width: 65px;
  height: 65px;
  position: absolute;
  user-select: none;
  touch-action: none;
}

.folder-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.folder-label {
  text-align: center;
  font-size: 12px;
  margin-top: 4px;
}

/* Add shadow to folder icons */
.folder-1 img,
.folder-2 img,
.folder-3 img,
.folder-4 img,
.folder-5 img,
.folder-6 img,
.folder-7 img,
.folder-8 {
  filter: drop-shadow(5px 7px 5px rgba(0, 0, 0, 0.3));
}

/* Initial positions */
.folder-1 {
  top: 180px;
  left: 45px;
}

.folder-2 {
  top: 300px;
  left: 20px;
}

.folder-3 {
  top: 380px;
  left: 280px;
}

.folder-4 {
  top: 510px;
  left: 50px;
}

.folder-5 {
  top: 280px;
  left: 150px;
}

.folder-6 {
  top: 180px;
  left: 250px;
}

.folder-7 {
  top: 395px;
  left: 145px;
}

.folder-8 {
  top: 510px;
  left: 195px;
}

/* Popup styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 45vh; /* Increased from 28.125vh to maintain 9:16 aspect ratio */
  height: 78vh; /* Increased from 45vh */
  max-height: 78vh; /* Increased from 45vh */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #d7d6d6;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.popup-title {
  margin: 0;
  font-size: 18px;
}

.popup-video-container {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.popup-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  text-align: center;
  color: black;
}

.bottom-logo {
  position: absolute;
  bottom: 5%; /* Adjust this value to position from bottom */
  left: 50%;
  transform: translateX(-50%);
  width: 185px; /* Adjust based on your desired logo width */
  height: auto; /* Maintains aspect ratio */
}

/* Carousel styles */
.carousel-container {
  overflow: hidden;
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  background-color: #f0f0f0;
}

.carousel-track {
  display: flex;
  animation: slide 90s linear infinite;
  height: 100%;
  width: fit-content;
}

.carousel-item {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 22px;
  color: #333;
  font-weight: bold;
  padding: 0 30px;
}

.carousel-dot {
  display: flex;
  align-items: center;
  margin: 0 15px;
  font-size: 14px;
  color: #333;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .folder-icon {
    width: 50px;
    height: 50px;
  }

  .folder-label {
    font-size: 10px;
  }

  /* Adjust initial positions for mobile */
  .folder-1 {
    top: 160px;
    left: 10px;
  }

  .folder-2 {
    top: 240px;
    left: 10px;
  }

  .folder-3 {
    top: 320px;
    left: 100px;
  }

  .popup-container {
    width: 75%; /* Increased from 47.5% */
    /* max-width: 67vh;  Increased to maintain aspect ratio */
    height: 73vh; /* Kept the same as desktop for consistency */
    margin-top: 7vh;
  }

  .popup-title {
    font-size: 16px;
  }

  .carousel-container {
    height: 60px;
  }

  .carousel-item {
    font-size: 20px;
    padding: 0 10px;
  }

  .carousel-dot {
    margin: 0 8px;
    font-size: 10px;
  }
}
