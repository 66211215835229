.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
/* Media query for mobile devices */
@media (max-width: 768px) {
  .header,
  .footer {
    padding: 0.3rem; /* Reduced padding for mobile */
  }

  .header {
    top: 5px;
    height: 35px; /* Slightly increased height to match the image */
    width: 61%; /* Reduced width to create space on sides */
    padding: 0 0.5rem; /* Added horizontal padding */
    border-radius: 20px; /* Added rounded corners to match the image */
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 10px rgb(248, 246, 246), 0 0 20px rgb(248, 246, 246),
      0 0 30px rgba(248, 246, 246), 0 0 40px rgba(248, 246, 246);
  }

  .nav-links {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }

  .nav-links a {
    font-size: 0.8rem;
    padding: 0.2rem 0.4rem;
    white-space: nowrap;
  }

  .nav-links a:first-child img {
    width: 28px;
    height: 28px;
  }

  .footer {
    font-size: 0.8rem; /* Smaller font size for footer on mobile */
  }
}

.header,
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  flex-shrink: 0;
}

.header {
  background-color: rgba(
    161,
    160,
    160,
    0.6
  ); /* Adjust color and opacity as needed */
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  border-radius: 35px;
  padding: 0.5rem 1rem;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgb(248, 246, 246), 0 0 20px rgb(248, 246, 246),
    0 0 30px rgba(248, 246, 246), 0 0 40px rgba(248, 246, 246);
}

.footer {
  background-color: black;
  width: 100%;
  padding: 1rem;
  z-index: 10;
  position: relative;
}

.footer p {
  color: white;
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 0.2rem 1rem;
  border-radius: 20px;
  background-color: rgba(128, 126, 126, 0.5);
  transition: background-color 0.3s ease;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}

.nav-links a:hover {
  background-color: rgba(100, 100, 100, 0.7);
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.5),
    0 0 12px rgba(255, 255, 255, 0.5);
}

.nav-links a img {
  width: 32px;
  height: 32px;
  margin-top: 0.1rem;
}

.home-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.particle-sphere-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  pointer-events: none; /* Allows interaction with the sphere behind it */
}

.animated-text {
  position: absolute;
  bottom: 4rem; /* Adjust the distance from the bottom to stay above the footer */
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem; /* Default font size */
  color: white;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7),
    0 0 10px rgba(255, 255, 255, 0.5);
}

.interaction-instructions {
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.6);
  font-size: 1rem;
  text-align: center;
  pointer-events: none;
  transition: opacity 0.5s ease;
}

@media (max-width: 1500px) {
  .animated-text {
    font-size: 2rem; /* Slightly smaller text for medium screens */
    bottom: 4rem; /* Maintain the same distance from the footer */
  }
}

@media (max-width: 768px) {
  .animated-text {
    font-size: 1.8rem; /* Smaller text for mobile devices */
    bottom: 4rem; /* Adjusted for mobile */
  }

  .interaction-instructions {
    font-size: 1rem;
    top: 9.4%;
  }
}

@media (max-width: 600px) {
  .animated-text {
    font-size: 1.6rem; /* Further reduction for very small devices */
    bottom: 8rem; /* Adjusted for very small screens */
  }
  .header {
    width: 66%; /* Reduced width to create space on sides */
  }
}

.changing-text {
  display: inline-block;
  min-width: 10ch;
}

.changing-text::after {
  content: "";
  display: inline-block;
  width: 12px; /* Adjust the size of the circle */
  height: 12px; /* Adjust the size of the circle */
  background-color: white; /* Adjust the color of the circle */
  border-radius: 50%; /* Makes the element a circle */
  animation: blink 0.8s infinite;
  margin-left: 2px; /* Optional: Adjust the spacing between the text and the circle */
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
